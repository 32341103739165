import React from "react"
import Logo from "./logo"
const UnderDevelopment = () => {
  return (
    <>
      <div className="codex_body">
        <div className="codex_logo_container">
          <Logo />
        </div>
        <div className="codex_under_development_banner">
          <p>
            Codex zit momenteel in een rebranding fase hierdoor is onze website
            even in ontwikkeling
          </p>
        </div>
        <div className="codex_under_development_title">
          <p>Wij bieden oplossingen voor elk soort onderneming!</p>
        </div>
        <div className="codex_under_development_subtitle">
          <p>Via onze formules ligt digitalisering binnen handbereik</p>
        </div>
        <div className="codex_under_development_description">
          <p>
            Werken met Codex geeft u de kans om een ​​softwareteam te hebben dat
            out of the box denkt en de digitale partner van uw bedrijf wil
            worden. Hierdoor kan u zich concentreren op uw core business.
            Terwijl wij uw website/webshop beheren en ook zelfs nieuwe software
            toepassingen kunnen uitwerken
          </p>
        </div>

        <div className="codex_under_development_subtitle">
          <p> Benieuwd wat dit allemaal kost voor uw onderneming?</p>
        </div>
        <div className="codex_under_development_description">
          <p>
            Contacteer ons gerust zodat wij u de nodige informatie kunnen
            bezorgen.
          </p>
        </div>
        <div className="codex_under_development_contact">
          <p>Stuur vrijblijvend een email naar:</p>
          <a href="mailto:Youness.ellamzi@code-experts.be">
            Youness.ellamzi@code-experts.be
          </a>
        </div>
      </div>
    </>
  )
}
export default UnderDevelopment
