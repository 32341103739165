import React from "react"
import UnderDevelopment from "../components/under_development"

import "../styles/index.scss"

export default function Home() {
  return (
    <>
      <UnderDevelopment />
    </>
  )
}
